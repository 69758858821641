
    import { mapState, mapMutations, mapActions } from 'vuex';
    import LoginMixin from '@/components/Utils/LoginMixin';
    import Logo from '@/components/Partials/Logo';
    import HeaderNavNonLoggedIn from '@/components/Partials/Headers/HeaderNavNonLoggedIn';

    export default {
        name: 'HeaderNonLoggedIn',

        components: {
            Logo,
            HeaderNavNonLoggedIn,
        },

        mixins: [LoginMixin],

        props: {
            fullWidth: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            ...mapState({
                $_appInfo: state => state.appInfo,
            }),
        },

        methods: {
            ...mapMutations('appInfo', {
                $_openSidebar: 'OPEN_SIDEBAR',
                $_closeSidebar: 'CLOSE_SIDEBAR',
                $_openMobileMenu: 'OPEN_MOBILE_MENU',
            }),
            ...mapActions('me', {
                $_getMe: 'getMe',
            }),

            openSidebar() {
                if (this.$_appInfo.isSidebarOpen) {
                    this.$_closeSidebar();
                } else {
                    this.$_openSidebar();
                }
            },
        },
    };
