
    export default {
        name: 'SearchResultLoading',

        props: {
            loading: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
            };
        },
    };
