
    import { mapMutations, mapActions } from 'vuex';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import {
        faBars as farBars,
    } from '@fortawesome/pro-regular-svg-icons/faBars';

    import {
        faPaperPlane as farPaperPlane,
    } from '@fortawesome/pro-regular-svg-icons/faPaperPlane';

    import Cookie from 'js-cookie';
    import { Bus } from '@/utils/bus';
    import datalayer from '@/utils/datalayer';

    export default {
        components: {
            FontAwesomeIcon,
        },

        data() {
            return {
                icons: {
                    farBars,
                    farPaperPlane,
                },
            };
        },

        methods: {
            ...mapMutations('appInfo', {
                $_OPEN_SIDEBAR: 'OPEN_SIDEBAR',
            }),

            ...mapActions('me', {
                $_storeAllUserData: 'storeAllUserData',
                $_storeOnesignal: 'storeOnesignal',
            }),

            ...mapActions('eventTracking', {
                $_pushEvent: 'pushEvent',
            }),

            $_dataLayerAfterLogin(seeker, dataLayer = {}) {
                const data = Object.assign(dataLayer, {
                    event: 'seeker_account_log_in',
                    seeker_id: seeker.id,
                    seeker_hash: seeker.email_hash,
                });
                this.$_pushEvent(data);
                datalayer(data);
            },

            $_storeUserAfterLogin(data) {
                this.$_storeAllUserData(data.data);
                Cookie.set(process.env.COOKIE_TOKEN_NAME, data.access_token, process.env.COOKIE_EXPIRY_TIME);
                this.$_pushOnesignal();
            },

            $_storeUserAfterRegister(data) {
                this.$_storeAllUserData(data.data);
                Cookie.set(process.env.COOKIE_TOKEN_NAME, data.access_token, process.env.COOKIE_EXPIRY_TIME);
                Bus.$emit('userRegistered');

                this.$_pushOnesignal();
            },

            $_pushOnesignal() {
                if (Cookie.get(process.env.COOKIE_ONESIGNAL)) {
                    this.$_storeOnesignal({ push_id: Cookie.get(process.env.COOKIE_ONESIGNAL) }).then(() => {
                        Cookie.remove(process.env.COOKIE_ONESIGNAL);
                    });
                }
            },

            loginResponse(response) {
                const dataLayer = { site_position: 'login_signup_popup' };
                this.$_dataLayerAfterLogin(response.data, dataLayer);
                this.$_storeUserAfterLogin(response);
                this.handleAfterLoginRedirect();
            },

            handleAfterLoginRedirect() {
                const qs = this.$route.query;
                let routerPush = { path: 'discover' };

                if (qs.redirect) {
                    const resolvedRouter = this.$router.resolve({ path: qs.redirect });
                    if (qs.redirect.startsWith('/account') || qs.redirect.startsWith('/onboarding') || qs.redirect.startsWith('/dashboard')) {
                        window.location.href = resolvedRouter.href;
                    } else {
                        routerPush = qs.redirect;
                        if (resolvedRouter.href !== '/') {
                            routerPush = { path: qs.redirect };
                        }
                        this.$router.push(routerPush);
                    }
                } else {
                    this.$router.push(routerPush);
                }
            },
        },
    };
