
    import { faSearch as farSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    export default {
        name: 'HeaderSearchButton',

        components: {
            FontAwesomeIcon,
        },

        data() {
            return {
                icons: {
                    farSearch,
                },
            };
        },

        methods: {
            handleSearchClick() {
                this.$store.commit('search/SET', { key: 'searchBlurbText', value: false });
                const routeNames = ['default-search-results'];
                if (routeNames.includes(this.$route.name)) {
                    window.scrollTo(0, 0);
                    this.$store.commit('search/SET', { key: 'isSearchSummary', value: false });
                    this.$store.commit('search/SET', { key: 'activateSearchAnimate', value: true });
                } else {
                    this.$router.push(this.$jobSearchDefaultUrlQuery);
                }
            },
        },
    };
