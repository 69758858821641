import has from 'lodash/has';

export default {
    push(id) {
        if (process.browser) {
            if (Array.isArray(id)) {
                id.forEach((o) => {
                    this.pushById(o);
                });
            } else {
                this.pushById(id);
            }
        }
    },

    pushById(id) {
        if (has(window, 'googletag.cmd')) {
            window.googletag.cmd.push(() => {
                window.googletag.display(id);
            });
        }
    },

    onSlotRenderEnded(callback) {
        if (has(window, 'googletag.cmd')) {
            window.googletag.cmd.push(() => {
                window.googletag.pubads().addEventListener('slotRenderEnded', (event) => {
                    callback(event);
                });
            });
        }
    },
};
