
    import { mapActions, mapMutations } from 'vuex';
    import HeaderSearchButton from '@/components/Partials/Headers/HeaderSearchButton';

    export default {
        name: 'HeaderNavNonLoggedIn',

        components: {
            HeaderSearchButton,
            PoweredByC1: () => import(/* webpackChunkName: "FeedbackModal" */'@/components/Partials/PoweredByC1'),
        },

        data() {
            return {
                showPromptModal: false,
                loginPrompt: {
                    component_name: 'LoginPrompt',
                    layout: 'sm',
                    code: 'LoginPrompt',
                    page_referral_element: 'header_nav',
                    site_position: 'login_signup_popup',
                    title: 'Login or sign up',
                    options: {
                        employer_site: true,
                    },
                },
            };
        },

        computed: {
            searchFilterMode() {
                const routeName = this.$route.name;
                return (routeName === 'default-search-results') && !this.$route.query.jobview;
            },
        },

        created() {
            if (this.$route.name === 'discover' || this.$route.name === 'discover-slug') {
                this.loginPrompt.options.login_redirect = '_self';
            }
        },

        methods: {
            ...mapActions('me', {
                $_pushToOnboarding: 'pushToOnboarding',
            }),

            ...mapMutations('auth', {
                $_SET_AUTH_OPTION: 'SET_AUTH_OPTION',
            }),

            handleSearchButton() {
                if (this.$route.name === 'default-search-results' && !this.$route.query.jobview) {
                    this.$store.commit('search/SET', { key: 'activeSearchFilter', value: true });
                    // window.location.hash = 'fullscreen';
                    document.body.classList.add('body--with-fullscreen-modal');
                    document.getElementById('inputKeywordSearch').focus();
                } else {
                    this.$router.push({ name: 'search' });
                }
            },

            openPromptModal() {
                this.showPromptModal = true;
            },

            closePromptModal() {
                this.showPromptModal = false;
            },
        },
    };
