
    import { mapMutations, mapGetters } from 'vuex';
    import AdsHelper from '@/utils/adsHelper';

    export default {
        name: 'AdUnit',

        props: {
            adId: {
                type: String,
                default: '',
                required: true,
            },
        },

        data() {
            return {
                adElement: '',
                test: '',
                init: true,
            };
        },

        computed: {
            ...mapGetters('search', {
                $_companyHasPaidJobs: 'companyHasPaidJobs',
            }),
        },

        mounted() {
            if (process.browser) {
                this.adElement = this.$el;
                this.setRenderedAdId();
            }
        },

        methods: {
            ...mapMutations('ads', {
                $_SET_RENDERED_AD_IDS: 'SET_RENDERED_AD_IDS',
            }),

            setRenderedAdId() {
                AdsHelper.onSlotRenderEnded((event) => {
                    const adId = event.slot.getSlotElementId();
                    const isEmpty = event.isEmpty;
                    if (!isEmpty && (adId === this.adId)) {
                        this.$_SET_RENDERED_AD_IDS(this.adId);
                    }
                });
            },
        },
    };
