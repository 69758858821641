
    export default {
        name: 'Pagination',

        props: {
            currentPage: {
                type: [String, Number],
                default: 1,
            },

            perPage: {
                type: [String, Number],
                default: 0,
            },

            totalRows: {
                type: [String, Number],
                default: 0,
            },
        },

        data() {
            return {};
        },

        computed: {
            prevText() {
                return this.$device.isDesktopOrTablet ? 'PREV' : '‹';
            },

            nextText() {
                return this.$device.isDesktopOrTablet ? 'NEXT' : '›';
            },

            hideGotoEndButtons() {
                return this.$device.isMobile || this.totalRows > 10000;
            },
        },

        methods: {
            changePage(data) {
                this.$emit('change', data);
            },
        },
    };
